<template>
  <div class="home">
    <video autoplay loop muted playsinline>
      <source
        v-if="portrait"
        src="@/assets/videos/tkl_promo_mobile_final_nosound.mp4"
        type="video/mp4"
      />
      <source
        v-else
        src="@/assets/videos/tkl_promo_final_V2_nosound.mp4"
        type="video/mp4"
      />
    </video>
    <section class="meet-the-players" v-if="$route.params.archiveID == null">
      <h2>{{ $t("home.meetThePlayers") }}</h2>
      <HomeSearch
        @search-function="searchRedirect($event)"
      />
      <div class="featured-players">
        <FeaturedPlayer
          v-for="(item, index) in players"
          :key="index"
          :content="item"
        />
      </div>
    </section>

    <div id="popup" class="overlay" v-if="promo">
      <div class="popup">
        <a class="close" id="close" @click="close">&times;</a>
        <a :href="link">
          <picture>
            <source
              :srcset="`${$bucketUrl}/images/promo/promo.webp`"
              type="image/webp"
            />
            <source
              :srcset="`${$bucketUrl}/images/promo/promo.png`"
              type="image/png"
            />
            <img :src="`${$bucketUrl}/images/promo/promo.png`" />
          </picture>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import FeaturedPlayer from "@/components/home/FeaturedPlayer.vue";
import HomeSearch from "@/components/home/HomeSearch.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    FeaturedPlayer,
    HomeSearch,
  },
  data() {
    return {
      players: [],
      portrait: null,
      promo: false,
      link: null,
    };
  },
  metaInfo() {
    return {
      title: this.$t("seo_metadata.home.title"),
      meta: [
        { vmid: "description", name: "description", content: this.$t("seo_metadata.home.description") },
        { vmid: "keywords", name: "keywords", content: this.$t("seo_metadata.keywords") },
        { vmid: "og:title", property: "og:title", content: this.$t("seo_metadata.home.title") },
        { vmid: "og:description", property: "og:description", content: this.$t("seo_metadata.home.description") },
        { vmid: "og:url", property: "og:url", content: "https://kingsleague.ca" },
        { vmid: "og:type", property: "og:type", content: "website"}
      ]
    };
  },
  methods: {
    close() {
      var modal = document.getElementById("popup");
      modal.style.visibility = "hidden";
      sessionStorage.setItem("promo", false);
    },
    async searchRedirect(item) {
      if(item.First_Name){
        this.$router.push({ path: `/players/${item._id}` });
      }else{
        let league = await axios.get(this.$apiUrl + `/teams/${item._id}/current-league`);
        league.data.message 
        ? this.$router.push({ path: `/team/${item._id}`})
        : this.$router.push({ path: `/team/${item._id}/${league.data[0]._id}`});
      }
    }
  },
  created() {
    this.portrait = window.innerWidth / window.innerHeight < 1;
  },
  beforeMount() {
    axios.get(this.$apiUrl + "/promo/").then((res) => {
      if (sessionStorage.getItem("promo") === "false") {
        this.promo = false;
      } else {
        this.promo = res.data.Enabled;
      }
      if (res.data.Link != "") {
        this.link = res.data.Link;
      }
    });
  },
  mounted() {
    axios.get(this.$apiUrl + "/extra/random-player/3").then((res) => {
      this.players = res.data;
      this.$store.dispatch("pageLoad", false);
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";

video {
  width: calc(100% + 30px);
  height: 100vh;
  object-fit: cover;
  object-position: center center;
  margin: -15px -15px 0 -15px;

  video source {
    width: 100%;
    height: auto;
  }
}

.home {
  margin: 0 !important;
  margin-top: -$navbar-height !important;
  max-width: none !important;
}

.featured-players {
  display: flex;
  margin: 0 -7.5px 0 -7.5px;

  .featured-player {
    flex: 1;
  }
}

h2 {
  margin-bottom: 15px;
}

.meet-the-players {
  padding-top: 15px;
  margin: 0 auto;
  max-width: $max-width;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 2000;
  visibility: visible;
  opacity: 1;
  width: 100%;
}
.popup {
  border: 2px solid $primary;
  display: flex;
  z-index: 2000;
  flex-direction: column;
  align-items: center;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 8px;
  width: fit-content;
  max-width: 90vw;
  max-height: 80vh;
  position: relative;
  img {
    max-width: 90vw;
    max-height: 80vh;
  }
  h6 {
    font-size: 10px;
  }
}

.popup h2 {
  margin-top: 0;
  color: #333;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: rgb(255, 0, 0);
}

.popup .close:hover {
  cursor: pointer;
  color: rgba(255, 0, 0, 0.623);
}

@media only screen and (max-width: $mobile-breakpoint) {
  .featured-players {
    flex-direction: column;
  }
}
</style>
